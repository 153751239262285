import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Container, Typography, makeStyles } from "@material-ui/core";

import EligibilityRequirements from "../EligibilityRequirements";
import NotificationBanner from "../NotificationBanner";
import SEO from "../../../common/SEO";
import SearchInput from "../SearchInput";
import ZipsInternalNav from "../ZipsInternalNav";
import Schema from "../schema/SearchAction";

const useStyles = makeStyles((theme) => ({
  welcomeSection: {
    padding: theme.spacing(5, 3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(15, 3),
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      height: "55vh",
      justifyContent: "center",
      padding: 0,
    },
  },
  title: {
    ...theme.typography.h3,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      ...theme.typography.h2,
      marginBottom: theme.spacing(4),
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 560,
      textAlign: "center",
    },
  },
}));

const LocationFinder = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const zips = useSelector((state) => Object.keys(state.locationFinder.zips));

  return (
    <>
      <SEO
        description={t("pageDescriptionHome")}
        title={t("pageTitleHome")}
      />
      <NotificationBanner />
      <Container className={classes.welcomeSection} maxWidth="md">
        <div>
          <Typography className={classes.title} component="h1">
            {t("title")}
          </Typography>
          <SearchInput />
        </div>
      </Container>
      <EligibilityRequirements />
      <ZipsInternalNav zips={zips} />
      <Schema />
    </>
  );
};

export default LocationFinder;
